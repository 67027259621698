import React, { useEffect, useState } from 'react';
import './faq-section.css';

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: "Как я могу участвовать в турнире?",
            answer: "Для участия в турнирах необходимо пройти регистрацию через любую доступную социальную сеть, выбрать турнир, зарегистрироваться на него и дождаться начала, за 10 минут данные будут опубликованы на странице с турниром."
        },
        {
            question: "Что я получу за победу в турнире?",
            answer: "Победители турниров и участники, оказавшиеся на призовых местах получают виртуальную валюту площадки x-ticket, которую можно обменять на реальные деньги, внутри игровую валюту Pubg Mobile или на билеты для участия в турнирах."
        },
        {
            question: "Сколько стоит участие в турнирах?",
            answer: "На разные турниры разная цена, она всегда указана в тикетах, рядом с названием турнира."
        },
        {
            question: "Что такое тикеты и какие они бывают?",
            answer: "Тикеты - валюта площадки xArena. Зелёные - бесплатные. За них можно участвовать только в бесплатных турнирах или обменять на платные по актуальному курсу. Синие - платные. Эти билеты можно приобрести на площадке, получить за участие в турнирах или выиграть в ежедневной лотерее."
        },
        {
            question: "В течение какого времени публикуются результаты турниров?",
            answer: "Результаты турниров публикуются в течение часа после их завершения."
        },
        {
            question: "Я хочу играть с друзьями, есть такая возможность?",
            answer: "Да, можно играть командой от 2-х человек, только не забудь зарегистрировать их в разделе 'команда' в профиле"
        },

    ];

    return (
        <section className="faq-section">
            <h2 className="faq-title">Часто задаваемые вопросы</h2>
            <div className="faqs">
                {faqs.map((faq, index) => (
                    <>
                        {index !== 0 && (
                            <hr/>
                        )}

                        <div key={index} className={`faq ${activeIndex === index ? 'active' : ''}`}>
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                <span>{faq.question}</span>
                                <span className="faq-icon">{activeIndex === index ? 'x' : '+'}</span>
                            </div>

                            <div className="faq-answer">
                                <p>{faq.answer}</p>
                            </div>
                        </div>
                    </>

                ))}
            </div>
        </section>
    );
}

export default FAQ;
