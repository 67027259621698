import React, {useEffect, useState} from 'react';
import './header.css';

const Header = () => {
    const [isTournamentsOpen, setTournamentsOpen] = useState(false);
    const [isRankingOpen, setRankingOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector('header');
            if (window.scrollY < 5) {
                header.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            } else {
                header.style.backgroundColor = 'rgba(0, 0, 0, 0.7)';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="header">
            <div className="logo">
                <img src="/images/main/xarena.png" alt="xArena - турниры pubg mobile, киберспорт"/>
            </div>
            <div className="line">
                <span>|</span>
            </div>
            <nav className="nav">
                <div
                    className="nav-item"
                    onMouseEnter={() => setTournamentsOpen(true)}
                    onMouseLeave={() => setTournamentsOpen(false)}
                >
                    <a href="#tournaments">Турниры</a>
                    {isTournamentsOpen && (
                        <div className="dropdown">
                            <p>Ближайшие турниры</p>
                            <div className="rate">
                                <span className="nickname">TOP-60</span>
                                <span className="rank">10 августа 11:30</span>
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className="nav-item"
                    onMouseEnter={() => setRankingOpen(true)}
                    onMouseLeave={() => setRankingOpen(false)}
                >
                    <a href="#ranking">Рейтинг</a>
                    {isRankingOpen && (
                        <div className="dropdown">
                            <p>Рейтинг игроков</p>
                            <div className="rate">
                                <img src="/images/logos/logo192.png" alt="xArena"/>
                                <span className="nickname">player</span>
                                <span className="rank">#1</span>
                            </div>
                            <div className="rate">
                                <img src="/images/logos/logo192.png" alt="xArena"/>
                                <span className="nickname">player</span>
                                <span className="rank">#2</span>
                            </div>
                            <div className="rate">
                                <img src="/images/logos/logo192.png" alt="xArena"/>
                                <span className="nickname">player</span>
                                <span className="rank">#3</span>
                            </div>
                            <div className="rate">
                                <img src="/images/logos/logo192.png" alt="xArena"/>
                                <span className="nickname">player</span>
                                <span className="rank">#4</span>
                            </div>
                        </div>
                    )}
                </div>
            </nav>
            <button className="header-button-play" onClick={() => window.location.href = 'https://app.x-arena.fun/login'}>Играть
            </button>
        </header>
    );
}

export default Header;
