import React from "react";
import "./footer.css";

const Footer = () => {

    return (
        <div className="footer">
            <div className="copyright">
                &copy; {new Date().getFullYear()} <span className="logo-red">x</span><span className="logo-gray">Arena</span> Все права защищены.
            </div>
            <div className="documents">
                <a href="/policy">Политика конфиденциальности</a>
                <a href="/eula">Пользовательское соглашение</a>
            </div>
        </div>
    );
}

export default Footer;
