import logo from './logo.svg';
import './App.css';
import Header from "./components/header/header";
import MainSection from "./components/main/main-section";
import Footer from "./components/footer/footer";
import FAQ from "./components/faq/faq-section";
import React from "react";

function App() {
  return (
      <>
          <Header/>
          <MainSection/>
          <FAQ/>
          <Footer/>
      </>

  );
}

export default App;
