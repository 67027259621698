import React from 'react';
import './main-section.css';

function MainSection() {

    return (
        <main className="main-section">
            <div className="image-container">
                <img
                    src="https://avatars.mds.yandex.net/i?id=93b59e5e9b01a1e28eaff4c83c6353d7ef793785-9181220-images-thumbs&n=13"
                    alt="xArena турниры pubg mobile, киберспортивная площадка" className="background-image" draggable="false"/>
                <div className="content">
                    <h1 className="title">Киберспорт начинается с тебя</h1>
                    <span className="subtitle">Стань лучшим среди друзей или во всем мире!</span>
                    <button className="start-button" onClick={() => window.location.href = 'https://app.x-arena.fun/login'}>Начать сражаться</button>
                </div>
            </div>

            <hr/>

            <div className="play-everywhere">
                <h2>Играй там, где захочешь!</h2>
                <span>Наша площадка доступна повсюду и для всех.</span>
                <span>Каждый игрок, вне зависимости от своего уровня может стать настоящим киберспортсменом и заработать на этом в <span className="logo-red">x</span><span className="logo-gray">Arena</span></span>

                <h3>1000+</h3>
                <span className="label">Игроков уже с нами</span>

                <h3>24/7</h3>
                <span className="label">Мы проводим турниры</span>
            </div>

            <div className="advantages">
                <div className="card">
                    <img src="/images/icons/honest.svg" alt="Icon 1" className="card-icon"/>
                    <h3 className="card-title">Честность</h3>
                    <p className="card-text">Мы внимательно следим за каждым турниром и выявляем нечестных игроков, в
                        свою очередь, если какой то игрок вызвал у тебя подозрения сообщите нам в поддержку!</p>
                </div>
                <div className="card">
                    <img src="/images/icons/support.svg" alt="Icon 2" className="card-icon"/>
                    <h3 className="card-title">Отзывчивая поддержка</h3>
                    <p className="card-text">Наша команда поддержки работает 24/7 и всегда пойдёт тебе навстречу,
                        поможет оперативно решить любой вопрос!</p>
                </div>
                <div className="card">
                    <img src="/images/icons/money.svg" alt="Icon 3" className="card-icon"/>
                    <h3 className="card-title">Заработок</h3>
                    <p className="card-text">На нашем площадке ты можешь заработать реальные деньги за участие в
                        турнирах. Участвуй в различных турнирах, получай тикеты и обменивай их на реальные деньги или
                        билеты для участия в турнирах!</p>
                </div>
                <div className="card">
                    <img src="/images/icons/registration.svg" alt="Icon 3" className="card-icon"/>
                    <h3 className="card-title">Простая регистрация</h3>
                    <p className="card-text">Удобная регистрация через социальные сети буквально в 2 клика!</p>
                </div>
                <div className="card">
                    <img src="/images/icons/formats.svg" alt="Icon 3" className="card-icon"/>
                    <h3 className="card-title">Множество форматов</h3>
                    <p className="card-text">Выбери турнир тебе по душе, заработай на топе или на киллах. Тдм или
                        классический матч - решать тебе</p>
                </div>
                <div className="card">
                    <img src="/images/icons/team.svg" alt="Icon 3" className="card-icon"/>
                    <h3 className="card-title">Соло или в команде</h3>
                    <p className="card-text">Хочешь - играй в одиночку, а можешь собрать целую команду и рвать рейтинги среди всех участников</p>
                </div>
            </div>

            <hr/>

            <div className="play-everywhere">
                <h2>Мы в социальных сетях</h2>

                <div className="advantages">
                    <div className="social-card">
                        <img src="/images/logos/vk_logo.png" alt="" className="social-card-icon"/>
                        <h3>ВКонтакте</h3>
                        <p className="social-card-text">
                            <button className="social-button">Перейти</button>
                        </p>
                    </div>

                    <div className="social-card">
                        <img src="/images/logos/tiktok_logo.png" alt="" className="social-card-icon"/>
                        <h3>TikTok</h3>
                        <p className="social-card-text">
                            <button className="social-button">Перейти</button>
                        </p>
                    </div>

                    <div className="social-card">
                        <img src="/images/logos/inst_logo.png" alt="" className="social-card-icon"/>
                        <h3>INSTAGRAM</h3>
                        <p className="social-card-text">
                            <button className="social-button">Перейти</button>
                        </p>
                    </div>

                    <div className="social-card">
                        <img src="/images/logos/telegram_logo.png" alt="" className="social-card-icon"/>
                        <h3>Телеграм</h3>
                        <p className="social-card-text">
                            <button className="social-button">Перейти</button>
                        </p>
                    </div>
                </div>


            </div>

            <hr/>

            <div className="play-everywhere">
                <h2>Что за форматы турниров?</h2>
                <span>Мы проводим турниры в различных форматах для разнообразия игрового процесса</span>
                <br/>
                <br/>
                <span><span className="logo-red"><b>TOP-??</b> </span>Турниры на вхождение в топ. Например, TOP-30 - за вхождение в топ 30 игрок получит награду, чем выше место в топе, тем круче награда</span>
                <br/>
                <br/>
                <span><span className="logo-red"><b>AirHunter</b> </span>Турниры на лут воздушных грузов. Награду получат игроки, залутавшие аирдропы. Больше аирдропов залутано - круче награда</span>
                <br/>
                <br/>
                <span><span className="logo-red"><b>KILLS</b> </span>Турниры на киллы. Нападай, атакуй, побеждай врага любым путём. Большая награда достанется игроку, совершившему большое количество киллов</span>
                <br/>
                <br/>
                <span><span className="logo-red"><b>TDM</b> </span>Турниры тдм 1vs1 или 4vs4, призы зачисляются команде победителей в зависимости от рейтинга в матче</span>
                <br/>
                <br/>
                <span><span className="logo-red"><b>Challenges</b> </span>Челленджи, например игра на сковородках или на пистолетах. Награда начисляется 1-му месту</span>
            </div>

        </main>
    );
}

export default MainSection;
